<template>
  <v-container>
    <work-in-progress></work-in-progress>
  </v-container>
</template>

<script>
export default {
  data: () => ({}),
  computed: {}
}
</script>
